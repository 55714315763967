@media (min-width: 768px) {
  .modal.left .modal-dialog {
    position:fixed;
    left: 0;
    margin: auto;
    width: 70vw;
    max-width: 100%;
    height: 100%;
    // -webkit-transform: translate3d(0%, 0, 0);
    // -ms-transform: translate3d(0%, 0, 0);
    // -o-transform: translate3d(0%, 0, 0);
    // transform: translate3d(0%, 0, 0);
  }


  .modal.fade.left.show .modal-dialog {
    transform: translate(0, 0);
  }

  .modal.fade.left .modal-dialog {
    transform: translate(-50px, 0);
  }

  .modal.left .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  //Video modals
  #videoModal .modal-dialog, #formModal .modal-dialog{
    max-width: 800px;
    margin-top: 4rem;
  }

}

@media (max-width: 768px) {
  .modal .modal-content {
    height: 100%;
    overflow-y: auto;
  }
}

.modal-body .row {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 32px;
}
.modal-body .row:last-child {
  border-bottom: 0;
}

.modal-header .highlight {
  background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba(#F2ADAB,0.3) 50%);
  padding: $pad-sm;
}