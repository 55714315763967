@import "variables";
@import "normalize";
@import "utilities";
@import "typography";
@import "colors";
@import "modals";

/* Global
--------------------------------------------- */


.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  position: relative;

  &::after {
    content: '';
    border: 1px solid $red;
    width: 100%;
    height: 100%;
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: -1;
  }

  &:hover {
    color: $light-blue;
  }
}

.tooltip {
  font-family: "Messina", sans-serif;
}

.highlight {
  background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($white,0.75) 50%);
  display: inline-block;
  padding: $pad-sm;
}

.animate__animated {
  opacity: 0;
}

blockquote {
  position: relative;

  font-size: 24px;
  font-weight: bold;


  h3 {
    margin: 0;
    color: $blue;
    font-size: 30px;
  }

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left:10vw;
  //   width: 150px;
  //   height: 100%;
  //   background-image: url(/img/quote.svg);
  //   background-repeat: no-repeat;
  // }
}

strong {
  font-family: "Messina", sans-serif;
  font-weight: bold;
}

section {
  padding: $pad-xl 0;
}

/* Header
--------------------------------------------- */
header {
  background: $light-blue;
  @media (min-width: 768px) {
    .cta {
      position: fixed;
      right:32px;
      top: 32px;
    }
  }
}

nav {
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: $pad-lg;
  position: relative;
    z-index: 10;

  .logo {
    width: 150px;
    @media (max-width: $tablet) {
      width: 100px;
    }
  }

  .btn {
    background: $blue;
    color: white;
    padding: 1rem 2rem;
    font-size: 18px;
    border-radius: 0;
    text-decoration: none;
    @media (max-width: $tablet) {
      padding: .5rem 1rem;
    }
  }
}



.banner {
  .title {
    padding: $pad-lg;
  }

  .title h1:nth-of-type(4) {
    margin-bottom: 16px;
  }
  h1 {
    margin-bottom:$pad-sm;
    margin-top: 0;
    font-size: 60px;
    text-transform: uppercase;
    line-height: 1;
    padding-left: 8px;
    padding-right: 8px;
    @media (max-width: $tablet) {
      font-size: 32px;
      margin-bottom: $pad-xs;
    }
  }
}

.blue-bkg {
  background-color: #bde3f0;
  margin-top: -500px;
  padding-top: 500px;
  position: relative;
  z-index: 0;
}


/* Intro
--------------------------------------------- */
section.intro {
  background: $blue;
  color: $white;
  padding: $pad-xl 0;
  position: relative;

  .intro-dots {
    position: absolute;
    top: -15px;
  }

  .intro-smiling {
    position: absolute;
    right: 32px;
    bottom: 0;
    @media (max-width: $tablet) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .lead {
    padding-bottom: $pad-xl;
  }

  .highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($white,0.3) 50%);
    padding: $pad-sm;
  }
}

/* Chairman Letter
--------------------------------------------- */
section.chairman {
  padding: $pad-xl 0;
  position: relative;
  background-image: url("/img/chairman-bkg.svg");
  background-repeat: no-repeat;
  background-position: right -40% top 0%;
  padding-bottom:0;

  @media (max-width: $tablet) {
    padding: $pad-md 0;
  }

  h2 {
    margin: 0;
    margin-bottom: $pad-md;
    color: white;
    @media (max-width: $tablet) {
      font-size: 36px;
      br {
        display:none;
      }
    }
  }

  .subtitle {
    opacity: .5;
    margin-bottom: 30vh;
    @media (max-width: $tablet) {
      margin-bottom: $pad-md;
    }
  }

  h5 {
    color: white;
    margin-bottom: $pad-lg;
  }

  .rick {
    position: absolute;
    left: 40%;
    bottom: 20%;
    @media (max-width: $tablet) {
      position: static;
      width: 150px;
      margin-bottom: $pad-md;
    }
  }

  blockquote {
    position: relative;
  }

  .chairman-radial {
    position: absolute;
    top: -10%;
    z-index: -1;
    right: 0;
  }

  .highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($white,0.3) 50%);
    padding: $pad-sm;
  }

  .embed-responsive {
    margin-bottom: $pad-xl;
  }

  .red-box {
    background: $red;
    height: calc(100% + 64px);
    width: 33%;
    top: -64px;
    position: absolute;
    @media (max-width: $tablet) {
      width: 100%;
      top: 0;
    }
  }

  .chairman-dots {
    position: absolute;
    left: -10%;
    bottom: 0;
    @media (max-width: $tablet) {
      display:none;
    }
  }

}

/* Timeline
--------------------------------------------- */
section.timeline {
  padding: $pad-xl 0;
  background: rgba($pink, .3);
  height: 100vh;

  @media (max-width: $tablet) {
    padding-top: 16px;
    padding-bottom: 16px;
    .content-right {
      padding:0;
    }
    p {
      font-size: 14px;
    }

  }

  h5 {
    margin: 0;
    line-height: 1;

  }
  .highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba(#F2ADAB,0.3) 50%);
    padding: $pad-sm;
  }

  .event:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::before {
      color: $red;
    }
  }
  .event {
    width: 400px;
    margin-right: 0px;
    flex-shrink: 0;
    padding: $pad-lg;
    box-sizing: border-box;

    position: relative;

    img {
      max-width: 80%;
      display: block;
      @media (max-width: $tablet) {
        max-width: 50%;
      }
    }
    @media (max-width: $tablet) {
      width: 100%;
    }

    p {
      font-size: 16px;
    }
  }

  .event::before {
    content: attr(data-year);
    position: absolute;
    bottom: 28px;
    border-bottom: 5px solid $red;
    line-height: 1;
    padding-left: 0;
    padding-right: 16px;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: "MessinaCondensed";

    // @media (max-width: $tablet) {
    //   content: none;
    // }
  }

  .event::after{
    content: attr(data-awards);
    font-size: 14px;
    position: absolute;
    bottom: 4px;
    padding: 0;
    border-top: 1px solid $red;
    width: 100%;
    padding-top: 10px;
    line-height: 1;
    letter-spacing: 1px;
    color: $red;
    text-transform: uppercase;
    padding-left: 0;
    font-family: "MessinaCondensed";
  }

  .event.pin {
    width: 180px;
    padding-top: 0;

    &.short {
      padding-top: 10vh;
    }
    &::before {
      content:'';
      position: absolute;
      left:0;
      border-left: 1px solid $red;
      height: 100%;
      border-bottom: 0;
    }
    .year {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1px;
      font-family: "MessinaCondensed";
      line-height: 1.2;
    }
    .awards {
      color: $red;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: "MessinaCondensed";
      line-height: 1.2;
    }
  }

  .year {
    margin: 0;
    font-size: 48px;
  }

  .title {
    margin: 0 0 8px 0;
    color: $blue;
    line-height: 1;
    font-size: 32px;
    @media (max-width: $tablet) {
      font-size: 24px;
    }
  }

  .content-right {
    // border-bottom: 1px solid $red;
  }

  .timeline-container {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .timeline-wrapper {
    display: flex;
    height: 100%;
  }
}

/* Mission
--------------------------------------------- */
section.mission {
  padding: 0;

  .highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba(#00A888,0.3) 50%);
  }

  h2 {
    margin: 0;
  }
}

/* Vision
--------------------------------------------- */
section.vision {
  margin-top: $pad-xl;
  background-image: url('/img/vision-smile.png'), linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(156,218,235,1) 100%);
  background-position: left -25% top 50%;
  background-size: 100%;
  background-repeat: no-repeat;

  .highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba(#00A888,0.3) 50%);

  }

  h2 {
    margin-top: 0;
    margin-bottom: 200px;
    @media (max-width: $tablet) {
      margin:0;
    }
  }

  @media (max-width: $tablet) {
    padding-bottom:0;
  }

}


/* Values
--------------------------------------------- */
section.values {
  background: linear-gradient(180deg, rgba(156,218,235,1) 0%, rgba(108,204,230,1) 100%);
  position: relative;
  .highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba(#00A888,0.3) 50%);

  }


  .steward {
    background-image: url('/img/values-steward-bkg.svg');
    background-position: center;
    background-size: cover;
    background-repeat:no-repeat;
    margin: $pad-md 0;
    padding: $pad-xl $pad-md;


    h2 {
      color: white;
    }
  }

  .impact {
    background-image: url('/img/values-impact-bkg.svg');
    background-position: center;
    background-size: cover;
    background-repeat:no-repeat;
    padding: $pad-xl $pad-md;



    h2 {
      color: white;
    }
  }

  @media (max-width: $tablet) {
    .health, .steward, .impact {
      padding: $pad-md 0;
      text-align: center;
    }
  }

  .col-md {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .vision-dots {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 0;
  }
}

/* Highlights
--------------------------------------------- */
section.donor-highlights {
  position: relative;
  padding-bottom:0;

  @media (max-width: $tablet) {
    padding:0;
  }

  .highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba(#00A888,0.3) 50%);
  }
  .col-md-10 {
    @media (max-width: $tablet) {
      padding:0;
    }
  }
  .subtitle {
    margin: 0;
    margin-bottom: $pad-sm;
  }
  .title {
    margin: 0;
    line-height: 1;
    margin-bottom: $pad-sm;
    color: $blue;
  }
  .number {
    color: $red;
    margin: 0;
  }

  .highlights-sullivan {
    position: relative;
    z-index: 10;
  }

  .pink-bkg {
    background-color: rgba($pink,.5);
    padding: $pad-lg 0;
    position: relative;
    top: -32px;

    .col-md-4 {
      position: relative;
      text-align: center;
      &:not(:last-child) {
        border-right: 3px dotted white;
      }
    }

    .highlights-donors {
      position: relative;
      left: -64px;
      top: -72px;
      @media (max-width: $tablet) {
        left:0;
        top:0;
        z-index: 100;
        margin-bottom: $pad-lg;
      }
    }

    .highlights-dta-dots {
      position: absolute;
      z-index: 0;
      top:-64px;
      left: 32px;
    }

    h2 {
      color: $red;
    }

    p {
      margin-left: 64px;
      margin-right: 64px;
      @media (max-width: $tablet) {
        margin:0;
      }
    }

    .highlights-faf-dots {
      position: absolute;
      z-index: 0;
      right: -32px;
      top: 0;
      @media (max-width: $tablet) {
        position: static;
        margin-bottom: $pad-md;
        margin-top: $pad-lg;
      }
    }

    svg {
      width: 140px;
      height: 140px;
      transform: rotate(-90deg);
      border-radius: 50%;
      margin:auto;
    }

    .dta-donut .chart {
      fill: #efefef;
      stroke: #004e9d;
      stroke-width: 32;
      stroke-dasharray: 65 100; /* for 38% */

    }

    .dta-donut-animation {
      animation: dta-fill 3s ease-in;
    }

    .dta-donut .overlay {
      fill: #6ccce6;
    }

    .faf-donut .chart {
      fill: #efefef;
      stroke: #ffdd00;
      stroke-width: 32;
      stroke-dasharray: 50 100; /* for 38% */

    }

    .faf-donut-animation {
      animation: faf-fill 3s ease-in;
    }

    .faf-donut .overlay {
      fill: #6ccce6;
    }

    @keyframes faf-fill {
      0% {
        stroke-dasharray: 0 100;
      }
      100% {
        stroke-dasharray: 50 100;
      }
    }

    @keyframes dta-fill {
      0% {
        stroke-dasharray: 0 100;
      }
      100% {
        stroke-dasharray: 65 100;
      }
    }
  }
}


/* Pledges
--------------------------------------------- */
section.pledges {
  @media (max-width: $tablet) {
    padding-top:0;
  }
  h1 {
    font-size: 140px;
    position: relative;
    color: $red;
    @media (max-width:$tablet) {
      font-size: 40px;
    }

    &::before {
      content: '$';
    }
  }

  hr {
    border-top-color: #00A888;
    margin-bottom: 64px;
    @media (max-width: $tablet) {
      margin-bottom: 16px;
    }
  }

  .pledge {
    display:flex;
    align-items: center;

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    .image {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translate(0, -50%);
        z-index: -1;
        width: 150px;
        height: 150px;
        // background: red;
        border-radius: 50%
      }

      &.founders::after {
        background: #b7dfef;
      }
      &.auction::after {
        background: #26cfb7;
      }
      &.annual::after {
        background: #ffed5e;
      }
      &.faf::after {
        background:#ffdede;
      }
    }
    img {
      padding: 32px;
      max-width: 200px;
      @media (max-width: $tablet) {
        padding:0;
        margin-top: $pad-xl;
        margin-bottom: $pad-md;
      }
    }
  }
  h3,p {
    margin: 0;
    line-height: 1.1;
  }
  @media (min-width: $desktop) {
    .row .pledge:nth-child(1) {
      border-right: 1px solid #00A888;
    }
    .row:nth-of-type(2) {
      border-bottom: 1px solid #00A888;
    }
  }
}

/* Map Global STyles
--------------------------------------------- */
.scholarship-winners, .grant-winners {
  .image {
    min-height: 200px;
    display: flex;
    align-items: center;
  }
  .link a{
    font-size: 16px;
    color: #004C98;
    &:hover {
      color: #E64E3D;
      text-decoration: none;
    }
  }
  .title {
    min-height: 120px;
  }

  svg text {
    fill: transparent;
    font-weight: bold;
    font-size: 1em;
  }

  svg g.region:hover text {
    fill: white;
  }

}

/* Scholarship Map
--------------------------------------------- */
section.scholarship-winners {
  background: rgb(76,213,192);
background: linear-gradient(180deg, rgba(76,213,192,1) 0%, rgba(255,255,255,1) 100%);

  svg.map {
    margin-bottom: 64px;
  }
  h5 {
    font-size: 16px;
  }


  g.region {
    cursor: pointer;

    &:hover path {
      stroke: #ffda01;
    stroke-width: 2px;
    fill: #ffda01;
    }
  }


  .play-button {
    max-height: 163px;
  }
  h5:not(.highlight) {
    color: $green;
    margin-bottom:8px;
  }
  h4 {
    margin: 0;
    margin-bottom: 16px;
  }
}

/* Grant Map
--------------------------------------------- */
section.grant-winners {
  background: rgb(255,200,201);
background: linear-gradient(180deg, rgba(255,200,201,1) 0%, rgba(255,255,255,1) 100%);

  svg.map {
    margin-bottom: 64px;
  }

  g.region {
    cursor: pointer;


    &:hover path{
      stroke: #16489e;
      stroke-width: 2px;
      fill: #16489e;
    }
  }



  h5:not(.highlight) {
    color: $red;
    margin-bottom:8px;
  }
  h4 {
    margin: 0;
    margin-bottom: 16px;
  }

  .play-button {
    max-height: 163px;
  }
}

/* Financials
--------------------------------------------- */
section.financials {
  background: #dbf3f9;
  @media (max-width: $tablet) {
    padding:$pad-sm 0;
  }

  .row {
    margin-bottom: 64px;
    @media (max-width: $tablet) {
      margin-bottom:0;
    }
  }

  h4,h1 {
    margin: 0;
  }

  .revenue div:last-child, .expenses div:first-child {
    padding: $pad-xl;
  }

  .revenue-bkg {
    position: absolute;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
  .expenses-bkg {
    position: absolute;
    top: 32px;
    right: 32px;
  }

  svg.revenue-chart {
    width: 100%;
    border-radius: 50%;
    transform: rotate(90deg);
    circle {
      fill: none;
      stroke-width: 32;
      r: 16;
      cx: 16;
      cy: 16;
      transform-origin: 16px 16px;
    }

    circle.background {
      fill: #fce6e6;
    }
    circle.blue {
      stroke: #004e9d;
      transform: rotate(31deg);
    }
    circle.green {
      stroke: #00a888;
      transform: rotate(4deg);
    }

    circle.red {
      stroke: #fb3433;
      transform: rotate(35deg);
    }

    circle.light-blue {
      stroke: #d0eaf4;
      transform: rotate(25deg);
    }

    circle.pink {
      stroke: #ffa8a9;
      transform: rotate(5deg);
    }
  }

  svg.expenses-chart {
    width: 100%;
    border-radius: 50%;
    transform: rotate(90deg);
    circle {
      fill: none;
      stroke-width: 32;
      r: 16;
      cx: 16;
      cy: 16;
      transform-origin: 16px 16px;
    }

    circle.background {
      fill: #fce6e6;
    }
    circle.blue {
      stroke: #004e9d;
      transform: rotate(60deg);
    }
    circle.green {
      stroke: #00a888;
      transform: rotate(5deg);
    }
    circle.red {
      stroke: #fb3433;
      transform: rotate(33deg);
    }
    circle.yellow {
      stroke: #ffda01;
      transform: rotate(2deg);
    }
  }

  .chart {
    padding: 32px;
  }

  .expenses .chart {
    padding: 64px;
  }

  table {
    margin: 32px 0;
    width: 100%;
  }

  table tr td{
    border-bottom: 1px solid $green;
    padding: 8px;
    border-collapse: collapse;
  }
  table tr:last-of-type td {
    border-bottom: 0;
  }

  .pie img {
    max-width: 30px;
    height: auto;
    display: block;
    max-height: 30px;
  }

  .title {
    font-size: 14px;
  }

  .amount {
    font-family: MessinaCondensed;
    font-weight: bold;
    font-size: 20px;
  }
}

/* Donors
--------------------------------------------- */
section.donors {
  padding-top: 0;
  background-color: rgba($pink,.3);
  .highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba(#00A888,0.3) 50%);
  }

  [role="tabpanel"] {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  [role="tabpanel"] ul {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
    width: 100%;
    padding: 0;

  @media (max-width: $tablet) {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
  }
  }

  [role="tabpanel"] ul > li {
    list-style:none;
    font-size: 14px;
    line-height: 1.6;
  }

  [role="tabpanel"] ul > li > a {
    color: #333;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: calc(100% + 4px);
      width: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid $pink;
    }
  }

  .company-tabs, .individual-tabs {
     [role="tab"] {
       background: $green;
       font-family: "MessinaCondensed";
       text-transform: uppercase;
       font-size: 16px;
       letter-spacing: 1px;
       border-top: 1px solid $medium-blue;
       height: 56px;
       display: flex;
       align-items: center;
       &:hover {
         background: $medium-blue;
       }
     }
     a {
       color: white;
       &:hover {
         text-decoration: none;
       }
     }
     [aria-selected="true"] {
       background: $medium-blue;
       color: #333;
       position: relative;
       outline: none;
       &:after {
         content: '';
         width: 0;
         height: 0;
         top: 50%;
         transform: translate(0,-50%);
         border-top: 27px solid transparent;
         border-bottom: 27px solid transparent;
         border-left: 27px solid $medium-blue;
         position: absolute;
         left: 100%;
       }
     }
  }

  .individual-tabs [role="tab"] {
    background-color: $blue;
  }
  .individual-tabs [aria-selected="true"] {
    background-color: $medium-blue;
  }

  .border {
    border: 1px dashed $red!important;
    padding: 8px;
  }
  @media (min-width: $desktop) {
    .grid {
      display: grid;
      grid-gap: 2em;
      grid-template-columns: 30% 70%;
    }

  }
}


/* CTA
--------------------------------------------- */
section.cta {
  background-color: $blue;
  color: $white;
  background-image: url('/img/cta-dots.svg');
  background-repeat: no-repeat;
  background-position: left -20% top 50%;
  @media (max-width: $tablet) {
    background-image:none;
  }
  a {
    color: white;
  }

  .social {
    width: 300px;
    height: 300px;
    background-image: url(/img/cta-social-circle.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    a {
      margin: 0 4px;
      color: white;
      font-size: 24px;
    }
    h4 {
      font-size: 32px;
    }
  }

}

/* footer
--------------------------------------------- */
footer.footer {
  background-color: $light-blue;
  padding: 32px 0;

  img {
    width: 160px;
  }

  p {
    margin: 0;
  }

  .btn {
    background: $blue;
    color: white;
    padding: 1rem 2rem;
    font-size: 18px;
    border-radius: 0;
    text-decoration: none;
    @media (max-width: $tablet) {
      padding: .5rem 1rem;
    }
  }
}

/* Dot Nav
--------------------------------------------- */
#dot-nav {
  position: fixed;
  top: 15vh;
  right: 5vw;
  font-size: 14px;
  color: $blue;
  li {
    list-style:none;
    margin: 0;
  }

  li.active a{
    color: $red;
  }
}
