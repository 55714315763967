.blue {
  color: $blue;
}

.green {
  color: $green;
}

.red {
  color: $red;
}