/* Typography
--------------------------------------------- */
@font-face {
  font-family: "Messina";
  src: url(/fonts/MessinaSansWeb-Book.woff2) format('woff2'),
       url(/fonts/MessinaSansWeb-Book.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Messina";
  src: url(/fonts/MessinaSansWeb-SemiBold.woff2) format('woff2'),
       url(/fonts/MessinaSansWeb-SemiBold.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Messina";
  src: url(/fonts/MessinaSansWeb-Bold.woff2) format('woff2'),
       url(/fonts/MessinaSansWeb-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MessinaCondensed";
  src: url(/fonts/MessinaSansCondensedWeb-Regular.woff2) format('woff2'),
       url(/fonts/MessinaSansCondensedWeb-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "MessinaCondensed";
  src: url(/fonts/MessinaSansCondensedWeb-Bold.woff2) format('woff2'),
       url(/fonts/MessinaSansCondensedWeb-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MessinaCondensed";
  src: url(/fonts/MessinaSansCondensedWeb-Black.woff2) format('woff2'),
       url(/fonts/MessinaSansCondensedWeb-Black.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

html {font-size: 112.5%;} /*18px*/

body {
  background-color: white;
  font-family: 'Messina', sans-serif;
  font-weight: 400;
  line-height: 1.65;
  color: #333;
}

p {margin-bottom: 1.15rem;}

h1, h2, h3, h4, h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: 'MessinaCondensed', sans-serif;
  font-weight: 700;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 64px;
}

h2 {
  font-size: 55px;
  font-weight:900;
}

h3 {
  font-size: 42px;
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

small, .text_small {font-size: 0.8em;}

@media (max-width: $tablet) {
  h1 {font-size: 48px;}
  h2 {font-size: 36px;}
  h3 {font-size:30px;}
}