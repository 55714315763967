/* Container Component
--------------------------------------------- */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 32px;
  padding-right: 32px;
}
@media (min-width: $tablet) {
  .container {
    width: 750px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: $desktop) {
  .container {
    width: 970px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: $widescreen) {
  .container {
    width: 1170px;
    padding-left: 0;
    padding-right: 0;
  }
}
