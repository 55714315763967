// Color variables
$blue: #024c98;
$light-blue: #d0eaf4;
$medium-blue: #6ccce6;
$green: #01a889;
$red: #E64E3D;
$pink: #f2adac;
$white: #ffffff;

// Breakpoints
$phone: 480px !default;
$tablet: 768px !default;
$desktop: 992px !default;
$widescreen: 1200px !default;

// Spacing
$pad-xs: 4px !default;
$pad-sm: 8px !default;
$pad-md: 16px !default;
$pad-lg: 32px !default;
$pad-xl: 64px !default;
